.newCoffee .btn-close {
  float: right;
  padding: 20px;
}

.buttonSpinner span {
  padding-left: 10px;
}

.newCoffee .react-rater {
  padding: 20px 0;
}

.newCoffee .react-rater-star {
  font-size: 2em;
  padding-right: 10px;
}

.newCoffee .btn-primary {
  margin-top: 20px;
}

.newCoffee form {
  padding: 50px 30px 0 30px;
}
