.productCard {
  font-size: 0.8rem;
  line-height: 1.4em;
}

.productCard .card-title {
  font-size: inherit;
  font-weight: bold;
  margin: 0;
}

.productCard .card-text {
  margin-top: 0.3rem;
}

.productCard .card-header {
  color: #ccc;
  background-color: #eee;
  text-align: center;
}
