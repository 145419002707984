.app-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 1em 2em 2em 2em;
}

.app-footer a {
  color: #000;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;

}

/* from: https://stackoverflow.com/questions/26975680/centering-svg-horizontally */

.column svg {
  max-height: 1em;
  margin: 0 auto; /* poor attempt at centering */
  height: auto !important; /* overrides inline */
  width: auto !important; /* overrides inline */
  max-width: 1em;
  display: block;
}
