.signupForm {
  padding: 20px;
}

.signupPromo {
    font-size: 0.9rem;
    color: #333;
    border: 1px solid #FCCE99;
    background-color: #FEE6CC;
    padding: 20px;
    margin: 20px 0 50px 0;
}

.signupForm .logo {
  padding: 20px 30%;
}
