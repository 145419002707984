.coffeePage {
  font-size: 0.875rem;
  /* line-height: 1.5em; */
}

.coffeePage .label {
  font-size: 0.5rem;
  color: #aaa;
  text-transform: uppercase;
  display: block;
  letter-spacing: .15rem;
}

.coffeePage .value {
  margin-bottom: 10px;
}

.coffeePage .image {
  padding: 20px 0;
}
