.beansCard {
  color: #000;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 15px;
  cursor: pointer;
}

.beansCard .coffeeName {
  font-weight: bolder;
}

.beansCard .coffeeBrand {
  font-size: 0.75rem;
}

.beansCard .userInfo {
  padding-top: 10px;
}

.beansCard .userInfo .when {
  color: #999;
}

.beansCard .who {
  font-weight: bolder;
}

.beansCard .comment {
  padding-left: 2px;
}

.beansCard .imageContainer {
  object-fit: fill;
  padding: .5em;
}

.beansCard .rating {
  padding-top: 20px;
  /* line-height: 2rem; */
}

.beansCard .react-rater {
  display: block;
  font-size: 15px;
}

.beansCard .container {
  padding: 0;
  float: left;
}

.beansCard .row {
  padding-bottom: 1em;
}

.beansCard .beanDetails {
  padding-top: 0.5em;
}

/*
https://reactgo.com/two-column-responsive-layout/
*/

.beansCard .boxContainer {
  display: flex;
}

.beansCard .boxContainer .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 70%; /* sets initial width to 70% */
  padding-right: 5px;
}

.beansCard .boxContainer .box {
  margin: 0;
  padding: 0;
}

/***** new *****/

.beansCard .container {
  display: grid;
  grid-template-columns: 60% 40%;
  min-height: 8rem;
}

.beansCard .column:first-child {
  text-align: left;
  padding-right: 1em;
}

.beansCard .column:nth-child(2) {

  background-color: #eee;
  border-radius: 5px;

  img {
    width: 100%;
    height: auto;
  }
}
