.unapprovedCard {

  padding: 1rem;
  margin-bottom: 2rem;

  .form-control, .coffeeCard {
    margin-bottom: 0.5rem;
  }

  .buttons {
    float: left;
    width: 100%;
    text-align: left;

    .feedback {
      float: right;
      padding: 0.3rem;

      .error {
        color: red;
        font-weight: bolder;
      }
    }
  }

  .buttons button {
    margin-right: 1rem;
  }

}

.unapprovedCard.done {
  color: #ddd;
}
