.proSettingsForm {

  .buttons {
    padding: 2rem;
    text-align: center;
  }

  .errorDetail {
    font-size: smaller;
  }
}
