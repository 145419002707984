.galleryParent {
  /* position: relative; */
  width: 100%;
  max-height: 600px;
  overflow: hidden;
  border-radius: 0.25rem!important;
}

.galleryParent .carousel.slide {
  max-height: 600px;
}

.galleryParent img {
  width: 100%;
  height: auto;
}
