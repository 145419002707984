.top-header {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #fff;
  height: 4rem;
  padding: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  -webkit-box-shadow: 0 -2px 6px 0px #222;
  -moz-box-shadow: 0 -2px 6px 0px #222;
  box-shadow: 0 -2px 6px 0px #222;
  z-index: 100;
}

.top-header.hide {
  top: -3rem;
}

.top-header strong {
  float: left;
  user-select: none;
}

.top-header svg {
  float: right;
}

.top-header .beta-badge {
  float: left;
  margin-left: 2em;
}
