.favoriteButton {

  button {
    margin-top: 1rem;
  }

  .disclaimer {
    color: #666;
    font-size: 0.9rem;
    padding: 1rem 2rem;
  }
}
