.proSettings {
  font-size: 0.8rem;

  .label {
    color: #aaa;
    text-transform: uppercase;
    display: block;
    letter-spacing: .1rem;
  }

  .value {
    margin: 5px 0 10px 0;
  }

  .card {
    border-radius: 0;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-title {
    padding: 10px;
    background-color: #eee;
    font-size: 1em;
    font-weight: bolder;
    text-transform: uppercase;
    display: block;
    letter-spacing: .1rem;
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }

  .rating {
    cursor: pointer;
    padding-top: 0.5rem;
  }

}
