.coffeeCard {
  color: #000;
  text-align: left;
  padding: 15px;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 15px;
  cursor: pointer;
}

.coffeeCard .coffeeName {
  font-weight: bolder;
}

.coffeeCard .coffeeBrand {
  font-size: 0.75rem;
}

.coffeeCard .userInfo {
  padding-top: 10px;
}

.coffeeCard .userInfo .when {
  color: #999;
}

.coffeeCard .who {
  font-weight: bolder;
}

.coffeeCard .comment {
  padding-left: 2px;
}

.coffeeCard .imageContainer img {
  max-width: 100%;
  max-height: 100%;
}

.coffeeCard .rating {
  padding-top: 20px;
  /* line-height: 2rem; */
}

.coffeeCard .react-rater {
  display: block;
  font-size: 15px;
}

/*
https://reactgo.com/two-column-responsive-layout/
*/

.coffeeCard .boxContainer {
  display: flex;
}

.coffeeCard .boxContainer .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 70%; /* sets initial width to 70% */
  padding-right: 5px;
}

.coffeeCard .boxContainer .box {
  margin: 0;
  padding: 0;
}
