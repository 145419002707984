.scanForm {
  text-align: center;

  strong {
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
  }

  .buttonSpinner span {
    padding-left: 10px;
  }
}

.photoContainer {

  button {
    margin: 2rem;
  }

  .react-html5-camera-photo {
    margin: 2rem 0;
  }
}

.scanningContainer {

  padding: 1rem;

  button {
    margin: 1rem;
  }
}

.noMatchContainer {
  p {
    margin: 2rem 0;
  }

  .textContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .textItem {
    color: #66bfbf;
    font-weight: bolder;
    font-size: smaller;
    border: 1px solid #66bfbf;
    background-color: #eaf6f6;
    padding: .2rem;
    margin: .2rem;
  }
}

.suggestionsContainer {
  button {
    margin: 1rem;
  }
  button.suggestion {
    width: 100%;
    padding: 1rem;
    margin: .5rem;
  }
}
