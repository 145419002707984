button.login {
  font-size: 20px;
  font-weight: bolder;
  padding: 20px;
}

button.login span {
  padding-left: 20px;
}

.loginForm {
  padding: 20px;
}

.loginForm .form-label {
  font-weight: bolder;
}

.loginForm .logo {
  padding: 60px 30%;
}

.signupLink {
  color: #333;
  border: 1px solid #FCCE99;
  background-color: #FEE6CC;
  padding: 10px;
  margin-top: 50px;
}

.signupLink a {
  margin-left: 0.3rem;
}
