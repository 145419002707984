.brandDetail {
  font-size: 0.875rem;
  /* line-height: 1.5em; */
}

.brandDetail .label {
  font-size: 0.5rem;
  color: #aaa;
  text-transform: uppercase;
  display: block;
  letter-spacing: .15rem;
}

.brandDetail .value {
  margin-bottom: 10px;
}

.brandDetail .rating {
  margin-left: 10px;
}

.brandDetail .logo {
  padding: 20px 0;
}

.brandDetail .products {
  margin-top: 20px;
}

.brandDetail .products .col {
  padding: 0 0 0 20px;
  margin: 0;
}

.brandDetail .productCard {
  margin-bottom: 15px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
}

/*
See: https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile
*/

@media (min-width:321px) {

  ul {
    column-count: 2;
    column-gap: 1.5rem;
  }

}
