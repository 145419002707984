
.beta-badge {
  font-family: Arial, sans-serif;
  font-size: 10px;
}

.beta-badge .label {
  background-color: #3cb55b;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  line-height: 10px;
  padding: 4px 6px;
}

.beta-badge .git {
  margin-left: 1em;
  color: #999;
}
