.data-field span {
  display: block;
}

.data-field .label {
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 1pt;
}

.data-field .value {
  font-size: 1em;
}
