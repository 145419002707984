.checkInContainer {
  padding: 2em;
  text-align: left;

  .beta-badge {
    display: inline;
    padding-left: .5rem;
  }
}

.checkInContainer .btn-close {
  float: right;
}

.checkInContainer .nav {
  margin: 2em 0;
}

.checkInContainer .form-label {
  margin: 1.5em 0 0.5em 0;
}

.checkInContainer .btn-primary {
  margin-top: 20px;
}
