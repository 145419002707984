.focusCard {

  color: #000;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.5em;
  margin-bottom: 15px;
  cursor: pointer;

  .container {
    padding: 0;
    float: left;
  }

  .row {
    padding-bottom: 1em;
  }

  .coffeeName {
    font-weight: bolder;
  }

  .coffeeBrand {
    font-size: 0.75rem;
  }

  /** aling 2-column layout **/

  .blabla {
    display: flex;
    align-items:center;
  }

  .metaInfo {
    width: 100%;
  }
}

/* Card customization */

:root {
  --card-background-color: #eee;
  --card-text-color: #000;
}

.focusCard.card {
  background-color: var(--card-background-color);
  border-color: var(--card-background-color);
  color: var(--card-text-color);
}
