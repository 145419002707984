.adminContainer h3 {
  font-weight: bolder;
  padding: 20px 0 0 0;
}

.unapprovedContainer {
  padding: 0 1rem;
}

.adminContainer .btn-close {
  float: right;
  padding: 20px;
}
