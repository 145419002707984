.experiments {

  h1 {
    padding-top: 1.5rem;
  }

  .btn-close {
    float: right;
    padding: 2rem;
  }

  .form {
    padding: 2rem;
  }

  .existing {
    width: 100%;
    padding: 0 2rem;
  }

  .xp {

    font-size: 1.5rem;
    text-align: left;
    clear: both;
    padding: 1rem 0;

    button {
      float: right;
    }
  }
}
