
.search-modal form {
  display: inline-block;
  margin-left: 1rem;
}

.search-result {
  padding: .5em 0;
  border-bottom: 1px solid #ddd;
}
