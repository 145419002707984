.feed-overview {

  .feed-container {
    padding: 20px;
  }

  main {
    background-color: #fff;
    padding-top: 4rem; /* should match header height */
    padding-bottom: 3.5rem; /* should match footer height */
  }

  .ptr__pull-down {
    top: 1rem;
  }

  .error {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    font-size: larger;
    color: lightcoral;
    padding: 1rem;
    margin: 0;
  }
}

.empty {
  padding: 10rem 2rem;

  h3 {
    font-size: xx-large;
  }

  p {
    font-size: large;
  }
}
