.editCheckInPage {

  .group {
    padding: 1rem 0;
  }

  .formRow {
    padding-top: 1rem;
  }

  button.cancel {
    float: right;
  }

  .saved {
    display: inline;
  }

  button.link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    /* font-family: arial, sans-serif; */
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
}
